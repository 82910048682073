import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { mapRSStatus } from './OrdersTable';
import { OrderStatuses, OrderStatusesValue } from './enums';

interface FiltersModalInput {
    openChangeStatusModal: boolean;
    currentStatus: OrderStatusesValue;
    shouldRemoveFromBalance: boolean;
    isRemovedFromBalance: boolean;
    onOrderStatusChange: (status: OrderStatusesValue) => void;
    handleCloseChangeStatusModal: () => void;
    onSubmitOrderStatusChange: () => void;
    onChangeShouldRemoveFromBalance: (value: boolean) => void;
}

const ChangeOrderStatusModal = ({
    openChangeStatusModal,
    currentStatus,
    shouldRemoveFromBalance,
    isRemovedFromBalance,
    onOrderStatusChange,
    handleCloseChangeStatusModal,
    onSubmitOrderStatusChange,
    onChangeShouldRemoveFromBalance,
}: FiltersModalInput) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleChange = (event: SelectChangeEvent<OrderStatusesValue>) => {
        const {
            target: { value },
        } = event;
        onOrderStatusChange(value);
    };

    const handleChangeRemoveFromBalance = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value === 'true';
        onChangeShouldRemoveFromBalance(value);
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={openChangeStatusModal}
            maxWidth='sm'
            fullWidth
            onClose={handleCloseChangeStatusModal}
        >
            <DialogTitle>Izaberi status</DialogTitle>
            <DialogContent>
                <FormControl variant='standard' fullWidth>
                    <InputLabel id='orders-status-filter'>Status</InputLabel>
                    <Select
                        labelId='orders-status-filter'
                        autoWidth={false}
                        value={currentStatus}
                        onChange={handleChange}
                    >
                        {Object.values(OrderStatuses).map((status) => {
                            if (status === OrderStatuses.TEST || status === OrderStatuses.DELETED) {
                                return;
                            }
                            return (
                                <MenuItem key={status} value={status}>
                                    <ListItemText primary={mapRSStatus(status)} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                {(currentStatus === OrderStatuses.COMPLETED ||
                    currentStatus === OrderStatuses.PROCESSING) &&
                !isRemovedFromBalance ? (
                    <FormControl>
                        <FormLabel id='shouldRemoveFromBalance-label'>
                            Da li zelite da skinete proizvode sa stanja?
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby='shouldRemoveFromBalance-label'
                            defaultValue={false}
                            name='shouldRemoveFromBalance'
                            value={shouldRemoveFromBalance}
                            onChange={handleChangeRemoveFromBalance}
                        >
                            <FormControlLabel value={false} control={<Radio />} label='Ne' />
                            <FormControlLabel value={true} control={<Radio />} label='Da' />
                        </RadioGroup>
                    </FormControl>
                ) : null}
                <DialogActions>
                    <Button variant='text' onClick={handleCloseChangeStatusModal}>
                        Odustani
                    </Button>
                    <Button variant='contained' onClick={onSubmitOrderStatusChange}>
                        Sacuvaj
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default ChangeOrderStatusModal;
