export function compareArrays<T extends object>(list1: T[], list2: T[]): T[] {
    const differences: T[] = [];

    // Iterate through both lists
    const maxLength = Math.max(list1.length, list2.length);

    for (let i = 0; i < maxLength; i++) {
        const obj1 = list1[i];
        const obj2 = list2[i];

        // If one list is shorter, add the remaining objects to differences
        if (!obj1 || !obj2) {
            differences.push(obj1 || obj2);
            continue;
        }

        const keys1 = Object.keys(obj1) as (keyof T)[];
        const keys2 = Object.keys(obj2) as (keyof T)[];

        // Check if the objects have different keys
        if (keys1.length !== keys2.length) {
            differences.push(obj1);
            continue;
        }

        // Compare key-value pairs
        let different = false;
        for (const key of keys1) {
            if (obj1[key] !== obj2[key]) {
                different = true;
                break;
            }
        }

        // If there is a difference, add the object from list1 to the differences array
        if (different) {
            differences.push(obj1);
        }
    }

    return differences;
}
