import { joiResolver } from '@hookform/resolvers/joi';
import { Add, Print, Save } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { isAxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { APIOrder } from '../../../app/entities/order';
import { APIOrderLine } from '../../../app/entities/orderLine';
import { getDirtyValues } from '../../../app/helpers/formHelpers';
import { compareArrays } from '../../../app/helpers/listCompare';
import { isEmptyObject } from '../../../app/helpers/objectManipulation';
import { buildOrderNumber } from '../../../app/mappers';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Auth, User } from '../../auths/types/user';
import AdminHeader from '../../components/AdminHeader';
import LegalCustomerDetailsForm from '../LegalCustomerDetailsForm';
import OrderCompletedModal from '../OrderCompletedModal';
import OrderInformation from '../OrderInformation';
import ShippingDetailsForm from '../ShippingDetailsForm';
import OrderLinesTable from '../create/OrdersCreateTable';
import {
    APIOrderProduct,
    OrderAuth,
    OrderForm,
    OrderLine,
    createOrderLineSchema,
    getOrderAuth,
} from '../create/createOrderForm';
import OrdersActions from '../create/formControllers/OrdersActions';
import { ProductsFormController } from '../create/formControllers/ProductsFormController';
import {
    OrderActionsType,
    OrderCustomerType,
    OrderPayingMethod,
    OrderShippingMethod,
    OrderStatuses,
} from '../enums';
import { printDocument } from '../printDocument';
import {
    UpdateOrderRequestInput,
    mapProductToOrderLine,
    mapToOrderLineList,
    updateOrderRequest,
    validateCreateOrderInput,
} from '../repositories/APIOrdersRepository';

const OrdersEdit = () => {
    const [order, setOrder] = React.useState<APIOrder>();
    const [updatedOrder, setUpdatedOrder] = React.useState<APIOrder>();
    const [apiOrderLinesList, setAPIOrderLinesList] = React.useState<Array<APIOrderLine>>([]);
    const [orderLineList, setOrderLineList] = React.useState<Array<OrderLine>>([]);
    const [initialOrderLineList, setInitialOrderLineList] = React.useState<Array<OrderLine>>([]);
    const [removedOrderLineList, setRemovedOrderLineList] = React.useState<Array<string>>([]);
    const [isOrderLineListDirty, setIsOrderLineListDirty] = React.useState<boolean>(false);
    const [productList, setProductList] = React.useState<Array<APIOrderProduct>>([]);
    const [orderAuthList, setOrderAuthList] = React.useState<Array<OrderAuth>>([]);
    const [displayShippingDetailsForm, setDisplayShippingDetailsForm] =
        React.useState<boolean>(false);
    const [displayLegalCustomerDetailsForm, setDisplayLegalCustomerDetailsForm] =
        React.useState<boolean>(false);
    const [openCompletedOrderModal, setOpenCompletedOrderModal] = React.useState<boolean>(false);

    const { id } = useParams();
    const axiosPrivate = useAxiosPrivate();
    const {
        control,
        formState: { errors, isSubmitting, dirtyFields },
        reset,
        getValues,
        setValue,
        handleSubmit,
        clearErrors,
    } = useForm<OrderForm>({
        resolver: joiResolver(createOrderLineSchema),
        mode: 'onTouched',
        defaultValues: React.useMemo(() => order, [order]),
    });

    React.useEffect(() => {
        if (Object.keys(errors).length > 0) {
            console.error('Form has errors:', errors);
        }
    }, [errors]);

    React.useEffect(() => {
        let isMounted = true;
        const abortController = new AbortController();
        async function loadOrder() {
            try {
                const response = await axiosPrivate.get(`/v2/orders/${id}`, {
                    signal: abortController.signal,
                });

                if (!response || !response.data.order || !response.data.orderLines || !isMounted) {
                    return;
                }
                const apiOrder: APIOrder = response.data.order;
                setOrder(apiOrder);
                setAPIOrderLinesList(response.data.orderLines);
                setOrderLineList(mapToOrderLineList(response.data.orderLines));
                setInitialOrderLineList(mapToOrderLineList(response.data.orderLines));
                setRemovedOrderLineList([]);
                setIsOrderLineListDirty(false);
                const customer = getOrderAuth(apiOrder.customer);

                const updateOrderForm: Partial<OrderForm> = {
                    _id: apiOrder._id,
                    name: apiOrder.name || '',
                    country: apiOrder.country || 'Srbija',
                    town: apiOrder.town || '',
                    address: apiOrder.address || '',
                    zip: apiOrder.zip || '',
                    phone: apiOrder.phone || '',
                    email: apiOrder.email || '',
                    companyName: apiOrder.companyName || '',
                    companyNumber: apiOrder.companyNumber || '',
                    companyTaxNumber: apiOrder.companyTaxNumber || '',
                    companyAddress: apiOrder.companyAddress || '',
                    companyTown: apiOrder.companyTown || '',
                    companyZip: apiOrder.companyZip || '',
                    companyPhone: apiOrder.companyPhone || '',
                    isPaid: apiOrder.isPaid,
                    isAssemblyRequired: apiOrder.isAssemblyRequired,
                    orderType: apiOrder.orderType,
                    payingMethod: apiOrder.payingMethod,
                    shippingMethod: apiOrder.shippingMethod,
                    status: apiOrder.status,
                    customer: customer,
                    customerType: apiOrder.customerType,
                    customerName: apiOrder.customerName,
                    customerEmail: apiOrder.customerEmail ?? '',
                    comment: apiOrder.comment ?? '',
                };
                reset(updateOrderForm);

                // If removed from balance show customer and skip loading auths and products
                if (apiOrder.isRemovedFromBalance) {
                    if (customer) {
                        setOrderAuthList([customer]);
                    } else {
                        setOrderAuthList([
                            {
                                _id: '',
                                email: apiOrder?.customerEmail ?? '',
                                name: apiOrder?.customerName,
                            },
                        ]);
                    }
                    return;
                }

                const responseAuths = await axiosPrivate.get('/v2/auths/orders', {
                    signal: abortController.signal,
                });

                if (responseAuths && responseAuths.data.auths && isMounted) {
                    setOrderAuthList(responseAuths.data.auths);
                }

                const responseProducts = await axiosPrivate.get('/v2/products/orders', {
                    signal: abortController.signal,
                });

                if (responseProducts && responseProducts.data.products && isMounted) {
                    setProductList(responseProducts.data.products);
                }
            } catch (error) {
                if (isAxiosError(error)) {
                    enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                    return console.error(error?.response?.data?.message);
                }
                console.error(error);
            } finally {
                isMounted = false;
            }
        }
        loadOrder();
        return () => {
            isMounted = false;
            abortController.abort();
        };
    }, [updatedOrder]);

    const addProductToList = async () => {
        const values = getValues();
        const alreadyAdded = orderLineList.find((order) => order.productId === values.product._id);
        if (alreadyAdded) {
            enqueueSnackbar(`Proizvod ${alreadyAdded.title} je vec dodat.`, { variant: 'warning' });
            return;
        }
        await getSingleProduct(values.product._id);
    };

    const getSingleProduct = async (id: string) => {
        const abortController = new AbortController();
        try {
            const response = await axiosPrivate.get(`/v2/products/${id}`, {
                signal: abortController.signal,
            });

            // TODO: revisit logic fro newOrderLine
            if (response && response.data.product) {
                const newOrderLine = mapProductToOrderLine(response.data.product);
                if (!newOrderLine) {
                    return;
                }
                setIsOrderLineListDirty(true);
                setOrderLineList((prevOrders) => [...prevOrders, newOrderLine]);
            }
        } catch (error) {
            if (isAxiosError(error)) {
                enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                return console.error(error?.response?.data?.message);
            }
            console.error(error);
        }
    };

    const getSingleAuth = async (id: string) => {
        const abortController = new AbortController();
        try {
            const response = await axiosPrivate.get(`/v2/auths/${id}`, {
                signal: abortController.signal,
            });

            if (response && response.data.auth && response.data.user) {
                const auth: Auth = response.data.auth;
                const user: User = response.data.user;
                setValue('email', auth.email);
            }
        } catch (error) {
            if (isAxiosError(error)) {
                enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                return console.error(error?.response?.data?.message);
            }
            console.error(error);
        }
    };

    const shippingMethod = useWatch({
        control,
        name: 'shippingMethod',
        defaultValue: OrderShippingMethod.STANDARD,
    });
    const payingMethod = useWatch({
        control,
        name: 'payingMethod',
        defaultValue: OrderPayingMethod.STANDARD,
    });
    React.useEffect(() => {
        const isShippingRequired = shippingMethod === OrderShippingMethod.SHIPPING;
        setDisplayShippingDetailsForm(isShippingRequired);
        if (!isShippingRequired) {
            clearErrors(['name', 'country', 'town', 'address', 'zip', 'phone', 'email']);
        }
        const isLegalCustomer = payingMethod === OrderPayingMethod.LEGAL;
        setDisplayLegalCustomerDetailsForm(isLegalCustomer);
        if (!isLegalCustomer) {
            clearErrors([
                'companyAddress',
                'companyName',
                'companyNumber',
                'companyPhone',
                'companyTaxNumber',
                'companyTown',
                'companyZip',
            ]);
        }
    }, [shippingMethod, payingMethod]);

    const customerType = useWatch({
        control,
        name: 'customerType',
        defaultValue: OrderCustomerType.NEW,
    });
    // const { data, error } = useOrderAuths(customerType, axiosPrivate);
    React.useEffect(() => {
        let isMounted = true;
        const abortController = new AbortController();
        async function loadOrderAuths() {
            try {
                const responseAuths = await axiosPrivate.get('/v2/auths/orders', {
                    signal: abortController.signal,
                });

                if (responseAuths && responseAuths.data.auths && isMounted) {
                    setOrderAuthList(responseAuths.data.auths);
                }
            } catch (error) {
                if (isAxiosError(error)) {
                    enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                    return console.error(error?.response?.data?.message);
                }
                console.error(error);
            } finally {
                isMounted = false;
            }
        }
        if (customerType === OrderCustomerType.REGULAR && orderAuthList.length === 0) {
            loadOrderAuths();
        }
        return () => {
            isMounted = false;
            abortController.abort();
        };
    }, [customerType]);

    const handleOpenCompletedOrderModal = () => {
        setOpenCompletedOrderModal(true);
    };

    const handleUpdateOrder = (shouldRemoveFromBalance: boolean) => {
        setOpenCompletedOrderModal(false);
        updateOrder(shouldRemoveFromBalance);
    };

    const handleCloseCompletedOrderModal = () => {
        setOpenCompletedOrderModal(false);
    };

    const handleRemoveFromOrders = (id: string) => {
        setOrderLineList((prevOrders) => prevOrders.filter((order) => order.productId !== id));
        setRemovedOrderLineList((prevOrdersLines) => {
            const removedOrderLine = orderLineList.find((orderLine) => orderLine.productId === id);
            if (removedOrderLine && removedOrderLine.orderLineId) {
                return [...prevOrdersLines, removedOrderLine.orderLineId];
            }
            return [...prevOrdersLines];
        });
        setIsOrderLineListDirty(true);
    };

    const handleChangeDesiredQuantity = (id: string, validatedInput: number) => {
        const updatedOrders = orderLineList.map((order) =>
            order.productId === id ? { ...order, desiredQuantity: validatedInput } : order,
        );
        setOrderLineList(updatedOrders);
        handleSetIsOrderLineListDirty(updatedOrders);
    };

    const handleChangeDiscount = (id: string, validatedInput: number) => {
        const updatedOrders = orderLineList.map((order) => {
            const price = ((100 - validatedInput) / 100) * order.originalPrice;
            const updatedOrder = {
                ...order,
                price: validatedInput > 0 ? price : order.originalPrice,
                discountPercentage: validatedInput,
            };
            return order.productId === id ? updatedOrder : order;
        });
        setOrderLineList(updatedOrders);
        handleSetIsOrderLineListDirty(updatedOrders);
    };

    const handleResetPrice = (id: string) => {
        const updatedOrders = orderLineList.map((order) =>
            order.productId === id
                ? {
                      ...order,
                      price: order.originalPrice,
                      discountPercentage: 0,
                  }
                : order,
        );
        setOrderLineList(updatedOrders);
        handleSetIsOrderLineListDirty(updatedOrders);
    };

    const handleChangePrice = (id: string, validatedInput: number) => {
        const updatedOrders = orderLineList.map((order) => {
            const discount = ((order.originalPrice - validatedInput) / order.originalPrice) * 100;
            const updatedOrder = {
                ...order,
                price: validatedInput,
                discountPercentage: validatedInput ? discount : 0,
            };
            return order.productId === id ? updatedOrder : order;
        });
        setOrderLineList(updatedOrders);
        handleSetIsOrderLineListDirty(updatedOrders);
    };

    const handleSetIsOrderLineListDirty = (updateOrders: Array<OrderLine>) => {
        if (compareArrays(initialOrderLineList, updateOrders)) {
            setIsOrderLineListDirty(true);
        } else {
            setIsOrderLineListDirty(false);
        }
    };

    // TODO za sutra -> napraviti taskove
    // - ako je placeno dodati input koliko je placeno -> ceka sonetov input

    if (!order || orderAuthList.length === 0) {
        return (
            <AdminHeader title={`Otpremnica (${buildOrderNumber(0)})`}>
                <Stack direction='row' spacing={2}>
                    <Button
                        fullWidth
                        type='submit'
                        variant='contained'
                        size='large'
                        disabled={true}
                    >
                        <Save />
                    </Button>
                    <Button fullWidth variant='contained' size='large' disabled={true}>
                        <Print />
                    </Button>
                </Stack>
            </AdminHeader>
        );
    }

    const updateOrder = async (shouldRemoveFromBalance?: boolean) => {
        const data = getValues();
        try {
            validateCreateOrderInput(orderLineList, data, order.isRemovedFromBalance);
            const formDataDirtyFields = getDirtyValues(dirtyFields, data);
            if (shouldRemoveFromBalance) {
                formDataDirtyFields.shouldRemoveFromBalance = shouldRemoveFromBalance;
            }
            formDataDirtyFields._id = order._id;
            const updateOrderRequstInput: UpdateOrderRequestInput = {
                order,
                orderLineList,
                removedOrderLineList,
                isOrderLineListDirty,
                formData: formDataDirtyFields,
                axiosPrivate,
            };
            const response = await updateOrderRequest(updateOrderRequstInput);
            if (!response || !response.updatedOrder || !response.updatedOrderLines) {
                throw Error('Otpremnica nije azurirana');
            }
            setOrder(undefined);
            setUpdatedOrder(response.updatedOrder);
        } catch (validationError) {
            console.error(validationError);
        }
    };

    const onSubmit = async (data: OrderForm) => {
        if (
            (data.status === OrderStatuses.COMPLETED || data.status === OrderStatuses.PROCESSING) &&
            !order.isRemovedFromBalance
        ) {
            return handleOpenCompletedOrderModal();
        }
        updateOrder();
    };

    const handlePrintDocument = () => {
        printDocument(order, apiOrderLinesList);
    };
    const isFormDirty = !isEmptyObject(dirtyFields);
    const isUpdateButtonDisabled = (!isFormDirty && !isOrderLineListDirty) || isSubmitting;
    const isPrintingDisabled = isFormDirty || isSubmitting || isOrderLineListDirty;

    return (
        <Stack>
            <form onSubmit={handleSubmit(onSubmit)}>
                <OrderCompletedModal
                    openCompletedOrderModal={openCompletedOrderModal}
                    handleUpdateOrder={handleUpdateOrder}
                    handleCloseCompletedOrderModal={handleCloseCompletedOrderModal}
                />
                <AdminHeader title={`Otpremnica (${buildOrderNumber(order.orderNumber)})`}>
                    <Stack direction='row' spacing={2}>
                        <Button
                            fullWidth
                            type='submit'
                            variant='contained'
                            size='large'
                            disabled={isUpdateButtonDisabled}
                        >
                            <Save />
                        </Button>
                        <Button
                            fullWidth
                            variant='contained'
                            size='large'
                            onClick={handlePrintDocument}
                            disabled={isPrintingDisabled}
                        >
                            <Print />
                        </Button>
                    </Stack>
                </AdminHeader>
                <Stack spacing={2} mb={5}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <OrdersActions
                            control={control}
                            errors={errors}
                            customerType={customerType}
                            auths={orderAuthList}
                            actionType={OrderActionsType.EDIT}
                            isStatusDisabled={isOrderLineListDirty}
                            // disableUserInput={
                            //     order.isRemovedFromBalance &&
                            //     order.status !== OrderStatuses.PROCESSING
                            // }
                            onAuthChange={getSingleAuth}
                        />
                        {displayShippingDetailsForm ? (
                            <ShippingDetailsForm
                                control={control}
                                errors={errors}
                                // disableUserInput={order.isRemovedFromBalance}
                            />
                        ) : null}
                        {displayLegalCustomerDetailsForm ? (
                            <LegalCustomerDetailsForm
                                control={control}
                                errors={errors}
                                // disableUserInput={order.isRemovedFromBalance}
                            />
                        ) : null}
                        <OrderInformation order={order} />
                    </Stack>
                    <Stack>
                        <OrderLinesTable
                            onRemoveFromOrders={handleRemoveFromOrders}
                            onResetPrice={handleResetPrice}
                            onChangePrice={handleChangePrice}
                            onChangeDiscount={handleChangeDiscount}
                            onChangeDesiredQuantity={handleChangeDesiredQuantity}
                            orderLines={orderLineList}
                            disableOrderLineInput={order.isRemovedFromBalance}
                        />
                        {!order.isRemovedFromBalance ? (
                            <Stack direction='row' alignItems='center' spacing={2}>
                                <ProductsFormController
                                    control={control}
                                    products={productList}
                                    errors={errors}
                                />
                                <Button
                                    type='button'
                                    variant='contained'
                                    size='large'
                                    onClick={addProductToList}
                                >
                                    <Add />
                                </Button>
                            </Stack>
                        ) : null}
                    </Stack>
                </Stack>
            </form>
        </Stack>
    );
};

export default OrdersEdit;
