import { Autorenew, Delete, Edit, MoreVert, Print } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Box,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import moment from 'moment';
import React, { RefObject } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import TablePaginationActions from '../../app/components/TablePaginationActions';
import { tableHeight } from '../../app/constants';
import { APIOrder } from '../../app/entities/order';
import { buildOrderNumber } from '../../app/mappers';
import ChangeOrderStatusModal from './ChangeOrderStatusModal';
import { UpdateOrderAPIInput } from './create/createOrderForm';
import { OrderPayingMethod, OrderShippingMethod, OrderStatusesValue } from './enums';

interface OrdersTableInput {
    orders: Array<APIOrder>;
    ordersCount: number;
    page: number;
    tableRef: RefObject<HTMLTableElement>;
    handleChangePage: (event: unknown, newPage: number) => void;
    deleteOrder: (orderId: string) => void;
    printDocument: (orderId: string) => void;
    handleUpdateOrder: (updatedOrderInput: Partial<UpdateOrderAPIInput>) => void;
}

function OrdersTable({
    orders,
    ordersCount,
    page,
    tableRef,
    handleChangePage,
    deleteOrder,
    printDocument,
    handleUpdateOrder,
}: OrdersTableInput) {
    const [openChangeStatusModal, setOpenChangeStatusModal] = React.useState(false);
    const [orderId, setOrderId] = React.useState('');
    const [currentStatus, setCurrentStatus] = React.useState('');
    const [shouldRemoveFromBalance, setShouldRemoveFromBalance] = React.useState(false);
    const [isRemovedFromBalance, setIsRemovedFromBalance] = React.useState(false);
    const navigate = useNavigate();
    const handleOpenChangeStatusModal = (
        id: string,
        status: OrderStatusesValue,
        isRemovedFromBalance: boolean,
    ) => {
        setOrderId(id);
        setCurrentStatus(status);
        setOpenChangeStatusModal(true);
        setIsRemovedFromBalance(isRemovedFromBalance);
    };
    const handleChangeStatus = (status: OrderStatusesValue) => {
        setCurrentStatus(status);
    };
    const handleChangeShouldRemoveFromBalance = (value: boolean) => {
        setShouldRemoveFromBalance(value);
    };
    const handleCloseChangeStatusModal = () => {
        setOpenChangeStatusModal(false);
    };

    const submitOrderStatusChange = () => {
        handleUpdateOrder({
            id: orderId,
            status: currentStatus,
            shouldRemoveFromBalance: shouldRemoveFromBalance,
        });
        handleCloseChangeStatusModal();
    };

    return (
        <Box>
            <ChangeOrderStatusModal
                openChangeStatusModal={openChangeStatusModal}
                currentStatus={currentStatus}
                shouldRemoveFromBalance={shouldRemoveFromBalance}
                isRemovedFromBalance={isRemovedFromBalance}
                onOrderStatusChange={handleChangeStatus}
                handleCloseChangeStatusModal={handleCloseChangeStatusModal}
                onSubmitOrderStatusChange={submitOrderStatusChange}
                onChangeShouldRemoveFromBalance={handleChangeShouldRemoveFromBalance}
            />
            <TableContainer component={Paper} sx={{ maxHeight: tableHeight }}>
                <Table
                    stickyHeader
                    ref={tableRef}
                    sx={{
                        minWidth: 650,
                        '& th, td': {
                            border: '1px solid #dddddd',
                        },
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Ime</TableCell>
                            <TableCell>Cena</TableCell>
                            <TableCell>Popust</TableCell>
                            <TableCell>Montaza</TableCell>
                            <TableCell>Placeno</TableCell>
                            <TableCell>Skinuto</TableCell>
                            <TableCell>Placanje</TableCell>
                            <TableCell>Preuzimanje</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Kreiran</TableCell>
                            <TableCell>Akcije</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map((order) => (
                            <TableRow
                                hover
                                key={order._id}
                                sx={{
                                    '&:last-child td, &:last-child th': { borderBottom: 0 },
                                    borderColor: 'rgba(224, 224, 224, 1)',
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                }}
                            >
                                <TableCell component='th' scope='row'>
                                    {buildOrderNumber(order.orderNumber)}
                                </TableCell>
                                <TableCell>{order.customerName || order.name}</TableCell>
                                <TableCell>
                                    {buildPrice(order.totalPrice, order.afterDiscountPrice)}
                                </TableCell>
                                <TableCell>{Number(order.totalDiscount).toFixed(0)}%</TableCell>
                                <TableCell>
                                    {order.isAssemblyRequired ? (
                                        <CheckBoxIcon color='success' />
                                    ) : (
                                        <CheckBoxOutlineBlankIcon />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {order.isPaid ? (
                                        <CheckBoxIcon color='success' />
                                    ) : (
                                        <CheckBoxOutlineBlankIcon />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {order.isRemovedFromBalance ? (
                                        <CheckBoxIcon color='success' />
                                    ) : (
                                        <CheckBoxOutlineBlankIcon />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {order.payingMethod === OrderPayingMethod.STANDARD
                                        ? 'Gotovinski'
                                        : 'Virmanski'}
                                </TableCell>
                                <TableCell>
                                    {order.shippingMethod === OrderShippingMethod.STANDARD
                                        ? 'Licno'
                                        : 'Slanje'}
                                </TableCell>
                                <TableCell>{mapRSStatus(order.status)}</TableCell>
                                <TableCell>
                                    {moment(order.created).locale('sr').format('DD MMM, YYYY')}
                                </TableCell>
                                <TableCell>
                                    <UsersActions
                                        orderId={order._id}
                                        orderStatus={order.status}
                                        orderIsRemovedFromBalance={order.isRemovedFromBalance}
                                        deleteOrder={deleteOrder}
                                        printDocument={printDocument}
                                        handleOpenChangeStatusModal={handleOpenChangeStatusModal}
                                        navigate={navigate}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25]}
                component='div'
                count={ordersCount}
                rowsPerPage={25}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                // onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
}

export const buildPrice = (totalPrice?: number, afterDiscountPrice?: number) => {
    if (totalPrice && totalPrice === afterDiscountPrice) {
        return (
            <Stack direction='row' spacing={1} alignItems='flex-end'>
                <Typography variant='body1'>{totalPrice}</Typography>
                <Typography variant='body1'>EUR</Typography>
            </Stack>
        );
    }
    return (
        <Stack direction='row' spacing={1} alignItems='flex-end'>
            <Typography variant='body2' color='grey' sx={{ textDecoration: 'line-through' }}>
                {totalPrice}
            </Typography>
            <Typography variant='body1'>{afterDiscountPrice}</Typography>
            <Typography variant='body1'>EUR</Typography>
        </Stack>
    );
};

export const mapRSStatus = (status?: string) => {
    switch (status) {
        case 'initialized':
            return 'Inicijalizovano';
        case 'processing':
            return 'Izdato';
        case 'ready':
            return 'Spremno';
        case 'shipped':
            return 'Poslato';
        case 'cancelled':
            return 'Otkazano';
        case 'completed':
            return 'Zavrseno';
        case 'disputed':
            return 'Problem';
        case 'refunded':
            return 'Vraceno';
        case 'test':
            return 'Test';
        case 'deleted':
            return 'Obrisano';

        default:
            return 'TEST';
    }
};

interface UsersActionsProps {
    orderId: string;
    orderStatus: string;
    orderIsRemovedFromBalance: boolean;
    deleteOrder: (orderId: string) => void;
    printDocument: (orderId: string) => void;
    handleOpenChangeStatusModal: (
        id: string,
        currentStatus: OrderStatusesValue,
        isRemovedFromBalance: boolean,
    ) => void;
    navigate: NavigateFunction;
}

const UsersActions = ({
    orderId,
    orderStatus,
    orderIsRemovedFromBalance,
    deleteOrder,
    printDocument,
    handleOpenChangeStatusModal,
    navigate,
}: UsersActionsProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const options = [
        {
            icon: <Edit />,
            text: 'Izmeni',
            handleOption: () => {
                navigate(`/orders/edit/${orderId}`);
            },
            disabled: false,
        },
        {
            icon: <Autorenew />,
            text: 'Izmeni status',
            handleOption: () => {
                handleOpenChangeStatusModal(orderId, orderStatus, orderIsRemovedFromBalance);
            },
            disabled: false,
        },
        {
            icon: <Print />,
            text: 'Stampaj otpremnicu',
            handleOption: () => {
                printDocument(orderId);
            },
            disabled: false,
        },
        {
            icon: <Delete />,
            text: 'Obrisi',
            handleOption: () => {
                deleteOrder(orderId);
            },
            disabled: false,
        },
    ];
    return (
        <Box>
            <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <Menu
                id='long-menu'
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option.text}
                        onClick={() => {
                            handleClose();
                            option.handleOption();
                        }}
                        disabled={option.disabled}
                    >
                        <ListItemIcon>{option.icon}</ListItemIcon>
                        {option.text}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default OrdersTable;
